






























import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import User from '@/users/models/User';
import {defineComponent, PropType} from '@vue/composition-api';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import Gate from '@/permissions/Gate';
import {PolicyMap} from '@/permissions/types/PolicyMap';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';

export default defineComponent({
  name: 'TorontoCHMB41HQuiz1Question2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
    },
  },
  data() {
    return {
      inputs: {value: [null, null]} as {value: (string | null)[]},
      imagePrefix: '/img/assignments/UofT/CHMB41H/quiz-1/question-2/',
      choicesA: {
        1: [
          '2-bromo-4-methoxybut-1-en-3-ol',
          '3-bromo-1-methenyl-propan-2-ol',
          '3-bromo-2-hydroxy-1-methoxy-but-3-ene',
          '3-bromo-1-methoxybut-3-en-2-ol',
          '1-methoxy-3-bromobut-3-en-2-ol',
        ],
        2: [
          '5-bromo-7-chloro-6-hydroxy-2,2,5-trimethyloct-7-en-4-one',
          '4-bromo-2-chloro-3-hydroxy-4,7,7-trimethyloct-1-en-5-one',
          '7-chloro-6-hydroxy-5-bromo-2,2,5-trimethyloct-7-en-4-one',
          '3-bromo-5-chloro-4-hydroxy-1-isopropyl-3-methyl-hex-5-en-2-one',
          '5-bromo-7-chloro-4-oxo-2,2,5-trimethyloct-7-en-6-ol',
        ],
        3: [
          '3-fluoro-6-hydroxy-1-methylhex-1-en-4-one',
          '1-hydroxy-4-fluoro-5-methylhex-5-en-3-one',
          '1-hydroxy-4-fluoro-4-isopropenyl-but-3-one',
          '4-fluoro-3-oxo-5-methylhex-5-en-1-ol',
          '4-fluoro-1-hydroxy-5-methylhex-5-en-3-one',
        ],
      } as Record<number, string[]>,
      choicesB: {
        1: [
          '6-methyl-3-ethylbicyclo[3.2.1]octane',
          '3-ethyl-1,5,6-dimethyl-cycloheptane',
          '3-ethyl-6-methylbicyclo[3.2.1]octane',
          '6-methyl-3-ethylbicyclo[3.2.1]septane',
          '6-ethyl-3-methylbicyclo[3.2.1]octane',
        ],
        2: [
          '6,6-dimethyl-dec-4-en-1-enal',
          '7-formyl-3,3-dimethylbicyclo[4.2.1]non-4-ene',
          '7-formyl-1,3,3,6-trimethylcyclooct-4-ene',
          '2-formyl-6,6-dimethylbicyclo[4.2.1]oct-6-ene',
          '3,3-dimethyl-7-formylbicyclo[4.2.1]non-4-ene',
        ],
        3: [
          '3-(2-oxocyclohexyl)propanoic acid',
          '4-(2-oxocyclohexyl)butanoic acid',
          '2-(3-propoxy)cyclohexanone',
          '1-(1-oxo-2-cyclohexyl)prop-3-anoic acid',
          '3-(1-oxo-2-cyclohexyl)propanoic acid',
        ],
      } as Record<number, string[]>,
    };
  },
  computed: {
    optionsA(): string[] {
      return seededShuffle(this.choicesA[this.version], this.seed);
    },
    optionsB(): string[] {
      return seededShuffle(this.choicesB[this.version], this.seed);
    },
    seed(): number {
      return (this.$gate as Gate<PolicyMap>).user?.id ?? 1;
    },
    version(): number {
      return this.taskState.getValueByName('version')?.content?.value ?? 1;
    },
    imageA(): string {
      return `${this.imagePrefix}partAv${this.version}.png`;
    },
    imageB(): string {
      return `${this.imagePrefix}partBv${this.version}.png`;
    },
  },
});
