var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v("Select the IUPAC name of the following organic compound.")]),_c('v-container',_vm._l(([
          ['A', _vm.imageA, _vm.optionsA],
          ['B', _vm.imageB, _vm.optionsB] ].entries()),function(ref){
        var partNumber = ref[0];
        var ref_1 = ref[1];
        var part = ref_1[0];
        var image = ref_1[1];
        var choices = ref_1[2];
return _c('v-row',{key:part},[_c('v-col',[_c('p',{staticClass:"mb-2 pl-3"},[_vm._v(" "+_vm._s(part)+") "),_c('v-img',{attrs:{"src":image,"alt":"Organic compound","width":"250px"}})],1),_c('v-radio-group',{attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.value[partNumber]),callback:function ($$v) {_vm.$set(_vm.inputs.value, partNumber, $$v)},expression:"inputs.value[partNumber]"}},_vm._l((choices),function(option){return _c('v-radio',{key:option,staticClass:"pl-8 pb-2",attrs:{"value":option},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(option)+" ")]},proxy:true}],null,true)})}),1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }